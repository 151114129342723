import "./NotFound.scss";

function NotFound() {

    return(
      <div>
          <h1 class="NotFoundHeader"> 404 Error </h1>
          <h2 class="NotFoundDescription"> The page you were looking for does not exist...</h2>
      </div>
    )
}

export default NotFound;